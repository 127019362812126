@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.navigation {
  max-width: 100%; }
  @media all and (max-width: 991px) {
    .navigation {
      position: absolute;
      width: 100%;
      height: 100vh;
      padding: 70px 15px 15px 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-sizing: border-box;
      z-index: 998; } }
  .navigation__wrap {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto; }
  .navigation__submenu-elem {
    margin-bottom: 1rem;
    line-height: 1; }
