@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.header {
  position: relative; }
  .header__wrapper {
    min-height: 54px; }
  .header__top {
    position: relative; }
  .header__main {
    min-height: 112px;
    border-top: 1px solid #DDE1E6;
    border-bottom: 1px solid #DDE1E6; }
  .header__search-btn, .header__burger-menu {
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-27px); }
  .header__burger-menu {
    right: 0;
    z-index: 999; }
  .header__search-btn {
    right: 55px; }
