.sidebar-nav {
  padding: 25px 20px;
  font-size: 1.125em;
  border-radius: 4px; }
  .sidebar-nav ul {
    margin: 0;
    padding: 0 0 0 16px;
    list-style: none; }
  .sidebar-nav li {
    display: block; }
    .sidebar-nav li:not(:last-child) {
      margin-bottom: 10px; }
  .sidebar-nav a:not(.btn) {
    display: block; }
