@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  position: relative; }
  .menu__item {
    height: 110px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 1rem; }
    .menu__item:hover {
      box-shadow: inset 0 -4px 0 0 #006db9; }

.menu > li > [role="menu"] {
  position: fixed;
  transform: translateY(0);
  transition: all 0.25s;
  list-style: none;
  top: 365px;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
  overflow-y: auto; }

.wcag_visible .menu > li > [role="menu"] {
  top: 267px; }

.menu > li > [aria-hidden="true"] {
  opacity: 0;
  transform: translateY(20px); }

body {
  transition: top 0.25s; }

.menu-open {
  position: fixed;
  overflow-y: scroll;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: top 0.25s; }
