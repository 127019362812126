@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.wcag-btn {
  display: inline-block;
  width: 48px;
  height: 38px;
  font-size: 0;
  line-height: 0;
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='32' viewBox='0 0 512 512' width='32' xmlns='http://www.w3.org/2000/svg' fill='%23fff' stroke='%23fff'%3E%3Cpath d='M256 128c-81.9 0-145.7 48.8-224 128 67.4 67.7 124 128 224 128 99.9 0 173.4-76.4 224-126.6C428.2 198.6 354.8 128 256 128zm0 219.3c-49.4 0-89.6-41-89.6-91.3 0-50.4 40.2-91.3 89.6-91.3s89.6 41 89.6 91.3c0 50.4-40.2 91.3-89.6 91.3z'/%3E%3Cpath d='M256 224c0-7.9 2.9-15.1 7.6-20.7-2.5-.4-5-.6-7.6-.6-28.8 0-52.3 23.9-52.3 53.3s23.5 53.3 52.3 53.3 52.3-23.9 52.3-53.3c0-2.3-.2-4.6-.4-6.9-5.5 4.3-12.3 6.9-19.8 6.9-17.8 0-32.1-14.3-32.1-32z'/%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  border: 1px solid #F4F7FB;
  border-radius: 4px;
  cursor: pointer;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out; }
  .wcag-btn:hover, .wcag-btn_active {
    background-color: #F4F7FB;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='32' viewBox='0 0 512 512' width='32' xmlns='http://www.w3.org/2000/svg' fill='%233b4256' stroke='%23fff'%3E%3Cpath d='M256 128c-81.9 0-145.7 48.8-224 128 67.4 67.7 124 128 224 128 99.9 0 173.4-76.4 224-126.6C428.2 198.6 354.8 128 256 128zm0 219.3c-49.4 0-89.6-41-89.6-91.3 0-50.4 40.2-91.3 89.6-91.3s89.6 41 89.6 91.3c0 50.4-40.2 91.3-89.6 91.3z'/%3E%3Cpath d='M256 224c0-7.9 2.9-15.1 7.6-20.7-2.5-.4-5-.6-7.6-.6-28.8 0-52.3 23.9-52.3 53.3s23.5 53.3 52.3 53.3 52.3-23.9 52.3-53.3c0-2.3-.2-4.6-.4-6.9-5.5 4.3-12.3 6.9-19.8 6.9-17.8 0-32.1-14.3-32.1-32z'/%3E%3C/svg%3E"); }

html[data-color="white"] .wcag-btn {
  background-color: #555;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='32' viewBox='0 0 512 512' width='32' xmlns='http://www.w3.org/2000/svg' fill='%23fff' stroke='%23fff'%3E%3Cpath d='M256 128c-81.9 0-145.7 48.8-224 128 67.4 67.7 124 128 224 128 99.9 0 173.4-76.4 224-126.6C428.2 198.6 354.8 128 256 128zm0 219.3c-49.4 0-89.6-41-89.6-91.3 0-50.4 40.2-91.3 89.6-91.3s89.6 41 89.6 91.3c0 50.4-40.2 91.3-89.6 91.3z'/%3E%3Cpath d='M256 224c0-7.9 2.9-15.1 7.6-20.7-2.5-.4-5-.6-7.6-.6-28.8 0-52.3 23.9-52.3 53.3s23.5 53.3 52.3 53.3 52.3-23.9 52.3-53.3c0-2.3-.2-4.6-.4-6.9-5.5 4.3-12.3 6.9-19.8 6.9-17.8 0-32.1-14.3-32.1-32z'/%3E%3C/svg%3E");
  border-radius: 0; }

html[data-color="white"] .wcag-btn:hover {
  background-color: #555;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='32' viewBox='0 0 512 512' width='32' xmlns='http://www.w3.org/2000/svg' fill='%23fff' stroke='%23fff'%3E%3Cpath d='M256 128c-81.9 0-145.7 48.8-224 128 67.4 67.7 124 128 224 128 99.9 0 173.4-76.4 224-126.6C428.2 198.6 354.8 128 256 128zm0 219.3c-49.4 0-89.6-41-89.6-91.3 0-50.4 40.2-91.3 89.6-91.3s89.6 41 89.6 91.3c0 50.4-40.2 91.3-89.6 91.3z'/%3E%3Cpath d='M256 224c0-7.9 2.9-15.1 7.6-20.7-2.5-.4-5-.6-7.6-.6-28.8 0-52.3 23.9-52.3 53.3s23.5 53.3 52.3 53.3 52.3-23.9 52.3-53.3c0-2.3-.2-4.6-.4-6.9-5.5 4.3-12.3 6.9-19.8 6.9-17.8 0-32.1-14.3-32.1-32z'/%3E%3C/svg%3E");
  outline: 2px solid #0000ff; }

html[data-color="white"] .wcag-btn:focus,
html[data-color="white"] .wcag-btn:target,
html[data-color="white"] .wcag-btn:active {
  background-color: #fff02a;
  outline: 2px solid #0000ff;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='32' viewBox='0 0 512 512' width='32' xmlns='http://www.w3.org/2000/svg' fill='%23111' stroke='%23111'%3E%3Cpath d='M256 128c-81.9 0-145.7 48.8-224 128 67.4 67.7 124 128 224 128 99.9 0 173.4-76.4 224-126.6C428.2 198.6 354.8 128 256 128zm0 219.3c-49.4 0-89.6-41-89.6-91.3 0-50.4 40.2-91.3 89.6-91.3s89.6 41 89.6 91.3c0 50.4-40.2 91.3-89.6 91.3z'/%3E%3Cpath d='M256 224c0-7.9 2.9-15.1 7.6-20.7-2.5-.4-5-.6-7.6-.6-28.8 0-52.3 23.9-52.3 53.3s23.5 53.3 52.3 53.3 52.3-23.9 52.3-53.3c0-2.3-.2-4.6-.4-6.9-5.5 4.3-12.3 6.9-19.8 6.9-17.8 0-32.1-14.3-32.1-32z'/%3E%3C/svg%3E"); }

html[data-color="black"] .wcag-btn {
  background-color: #ccc;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='32' viewBox='0 0 512 512' width='32' xmlns='http://www.w3.org/2000/svg' fill='%231d1d1d' stroke='%23ccc'%3E%3Cpath d='M256 128c-81.9 0-145.7 48.8-224 128 67.4 67.7 124 128 224 128 99.9 0 173.4-76.4 224-126.6C428.2 198.6 354.8 128 256 128zm0 219.3c-49.4 0-89.6-41-89.6-91.3 0-50.4 40.2-91.3 89.6-91.3s89.6 41 89.6 91.3c0 50.4-40.2 91.3-89.6 91.3z'/%3E%3Cpath d='M256 224c0-7.9 2.9-15.1 7.6-20.7-2.5-.4-5-.6-7.6-.6-28.8 0-52.3 23.9-52.3 53.3s23.5 53.3 52.3 53.3 52.3-23.9 52.3-53.3c0-2.3-.2-4.6-.4-6.9-5.5 4.3-12.3 6.9-19.8 6.9-17.8 0-32.1-14.3-32.1-32z'/%3E%3C/svg%3E");
  border-radius: 0; }

html[data-color="black"] .wcag-btn:hover {
  background-color: #ccc;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='32' viewBox='0 0 512 512' width='32' xmlns='http://www.w3.org/2000/svg' fill='%231d1d1d' stroke='%23ccc'%3E%3Cpath d='M256 128c-81.9 0-145.7 48.8-224 128 67.4 67.7 124 128 224 128 99.9 0 173.4-76.4 224-126.6C428.2 198.6 354.8 128 256 128zm0 219.3c-49.4 0-89.6-41-89.6-91.3 0-50.4 40.2-91.3 89.6-91.3s89.6 41 89.6 91.3c0 50.4-40.2 91.3-89.6 91.3z'/%3E%3Cpath d='M256 224c0-7.9 2.9-15.1 7.6-20.7-2.5-.4-5-.6-7.6-.6-28.8 0-52.3 23.9-52.3 53.3s23.5 53.3 52.3 53.3 52.3-23.9 52.3-53.3c0-2.3-.2-4.6-.4-6.9-5.5 4.3-12.3 6.9-19.8 6.9-17.8 0-32.1-14.3-32.1-32z'/%3E%3C/svg%3E");
  outline: 2px solid #ccc; }

html[data-color="black"] .wcag-btn:focus,
html[data-color="black"] .wcag-btn:target,
html[data-color="black"] .wcag-btn:active {
  background-color: #fff02a;
  outline: 2px solid #ccc;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='32' viewBox='0 0 512 512' width='32' xmlns='http://www.w3.org/2000/svg' fill='%23111' stroke='%23111'%3E%3Cpath d='M256 128c-81.9 0-145.7 48.8-224 128 67.4 67.7 124 128 224 128 99.9 0 173.4-76.4 224-126.6C428.2 198.6 354.8 128 256 128zm0 219.3c-49.4 0-89.6-41-89.6-91.3 0-50.4 40.2-91.3 89.6-91.3s89.6 41 89.6 91.3c0 50.4-40.2 91.3-89.6 91.3z'/%3E%3Cpath d='M256 224c0-7.9 2.9-15.1 7.6-20.7-2.5-.4-5-.6-7.6-.6-28.8 0-52.3 23.9-52.3 53.3s23.5 53.3 52.3 53.3 52.3-23.9 52.3-53.3c0-2.3-.2-4.6-.4-6.9-5.5 4.3-12.3 6.9-19.8 6.9-17.8 0-32.1-14.3-32.1-32z'/%3E%3C/svg%3E"); }
