@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.toggle-btn {
  position: absolute;
  top: 0px;
  left: 13px;
  width: 25px;
  height: 25px;
  font-size: 0;
  line-height: 0;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer; }
  .toggle-btn span {
    display: inline-block;
    position: absolute;
    left: 8px;
    top: 7px;
    width: 9px;
    height: 9px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(45deg); }
  .toggle-btn[aria-expanded="true"] span {
    top: 10px;
    transform: rotate(225deg); }
