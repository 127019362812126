@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.news-item__preview {
  float: left;
  width: 160px;
  height: auto;
  margin-right: 15px;
  border-radius: 4px; }
  @media all and (max-width: 575px) {
    .news-item__preview {
      width: 100%; } }

.news-item__description {
  display: block;
  overflow: hidden; }
  @media all and (max-width: 575px) {
    .news-item__description {
      width: 100%; } }

.news-item__title {
  margin: 0 0 0.5rem;
  font-size: 1.125rem;
  line-height: 1.44;
  font-weight: normal;
  text-overflow: ellipsis;
  overflow: hidden; }

.news-item__date {
  font-size: 0.875rem; }
