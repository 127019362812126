html[data-color="white"] .menu > li > [role=menu] {
  background-color: #f5f5f5;
  color: #555; }

html[data-color="white"] .burger span {
  background-color: #555; }

html[data-color="white"] .menu__item:focus .burger span {
  background-color: #111; }

html[data-color="black"] .menu > li > [role=menu] {
  background-color: #000;
  color: #ccc; }

html[data-color="black"] .burger span {
  background-color: #ccc; }

html[data-color="black"] .menu__item:focus .burger span {
  background-color: #111; }

html:not([data-color="default"]) .menu__item {
  min-height: 110px;
  height: 100%; }

html:not([data-color="default"]) .menu__item:hover, html:not([data-color="default"]) .menu__item:focus {
  box-shadow: none; }

html[data-fontsize="big"] .menu > li > [role=menu] {
  top: 173px; }

html[data-fontsize="big"] .wcag_visible .menu > li > [role="menu"] {
  top: 274px; }

html[data-fontsize="large"] .menu > li > [role=menu] {
  top: 179px; }

html[data-fontsize="large"] .wcag_visible .menu > li > [role="menu"] {
  top: 280px; }

html[data-fontsize="large"][data-kerning="big"] .menu > li > [role=menu] {
  top: 199px; }

html[data-fontsize="large"][data-kerning="big"] .wcag_visible .menu > li > [role="menu"] {
  top: 300px; }
