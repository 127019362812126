.logo__img {
  margin-right: 15px; }

.logo__title {
  position: relative;
  display: block;
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-weight: normal;
  overflow: hidden; }

.logo__title span {
  display: block; }

.logo__subtitle {
  margin-top: 5px;
  font-size: 1rem;
  line-height: 1rem; }
