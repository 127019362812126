@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.footer .footer__copyright-studio {
  float: right; }

.footer .footer__copyright-15web {
  margin-left: 5px;
  padding: 0 0 2px 26px;
  background: url(img/i-15web.png) no-repeat 0 50%; }

.footer .footer__copyright-15web a span {
  padding-bottom: 1px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
