@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
h1, .h1 {
  font-size: 2.375rem;
  line-height: 2.825rem;
  font-weight: 500;
  margin: 2.5rem 0; }
  @media all and (max-width: 575px) {
    h1, .h1 {
      font-size: 2rem;
      line-height: 2.5rem; } }

h2, .h2 {
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 500;
  margin: 1.5rem 0; }
  @media all and (max-width: 575px) {
    h2, .h2 {
      font-size: 1.5rem;
      line-height: 2rem; } }

h3, .h3 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
  margin: 1rem 0; }
  @media all and (max-width: 575px) {
    h3, .h3 {
      font-size: 1.3rem;
      line-height: 1.8rem; } }

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child,
.h1:first-child, .h2:first-child, .h3:first-child {
  margin-top: 0; }

h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child,
.h1:last-child, .h2:last-child, .h3:last-child {
  margin-bottom: 0; }
